import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { useLocation } from 'react-router-dom';
import './FurnitureCustomizer.css'; // Import the CSS file for styling

// Helper function to parse URL parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Model({ path }) {
  const { scene } = useGLTF(path);
  return <primitive object={scene} />;
}

function FurnitureCustomizer() {
  const [color, setColor] = useState('#ffffff'); // Default color
  const [productInfo, setProductInfo] = useState({
    name: '',
    price: '',
    description: '',
  });

  const query = useQuery();

  useEffect(() => {
    const productId = query.get('product_id');
    const productName = query.get('name');
    const productPrice = query.get('price');

    // Set the product info from URL parameters
    if (productId && productName && productPrice) {
      setProductInfo({
        name: productName,
        price: productPrice,
        description: `This is a product description for ${productName}. Customize it to your liking!`,
      });
    }
  }, [query]);

  return (
    <div className="customizer-container">
      <h1>Customize Your {productInfo.name}</h1>

      {/* 3D Model Preview */}
      <div className="canvas-container">
        <Canvas>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <mesh>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={color} />
          </mesh>
          <Model path="/halo.glb" />
          <OrbitControls />
        </Canvas>
      </div>

      {/* Color Picker */}
      <div className="color-picker">
        <label>Select Color: </label>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </div>

      {/* Product Info */}
      <div className="product-info">
        <h2>Product Details</h2>
        <p><strong>Price:</strong> {productInfo.price}</p>
        <p><strong>Description:</strong> {productInfo.description}</p>
      </div>
    </div>
  );
}

export default FurnitureCustomizer;
