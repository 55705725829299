// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FurnitureCustomizer from './FurnitureCustomizer';

function App() {
  return (
    <Router>
      <div className="App">
        <h1>Furniture Customizer</h1>
        <Routes>
          <Route path="/" element={<FurnitureCustomizer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
